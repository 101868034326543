import { useState } from 'react'
import { PiDot } from 'react-icons/pi'

const Cadastrar = ({ setModalCadastro, receberDados }) => {
    const token = localStorage.getItem('key')
    const [shake, setShake] = useState(false)
    const [erros, setErros] = useState([])
    const [dados, setDados] = useState({
        username: '',
        password: '',
        email: '',
        id_plano: ''
    })

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target

        if (type === 'checkbox') {
            setDados(prevDados => ({
                ...prevDados,
                id_plano: checked ? value : ''
            }))
        } else {
            setDados(prevDados => ({
                ...prevDados,
                [name]: value,
            }))
        }
    }

    const cadastrar = async (dados) => {
        try {
            const response = await fetch('https://transcritor-ia.com:8086/api/administracao/usuario/', {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
                credentials: 'include',
                body: JSON.stringify(dados),
            });
    
            if (response.ok) {
                console.log('Cadastrado com sucesso');
                receberDados()
                setModalCadastro(false);
            } else {
                const resposta = await response.json();
                const mensagensErro = [];

                if (resposta.detail) {
                    mensagensErro.push(resposta.detail);
                } else if (Array.isArray(resposta)) {
                    mensagensErro.push(...resposta);
                } else {
                    for (const key in resposta) {
                        if (Array.isArray(resposta[key])) {
                            mensagensErro.push(...resposta[key]);
                        } else {
                            mensagensErro.push(resposta[key]);
                        }
                    }
                }
    
                setErros(mensagensErro);
                console.log(mensagensErro);
                setShake(true);
                setTimeout(() => setShake(false), 500);
            }
        } catch (error) {
            console.error('Erro ao cadastrar:', error);
            setErros(['Erro inesperado ao cadastrar. Tente novamente mais tarde.']);
        }
    };
    

    return (
        <main className="absolute h-full w-full flex items-center justify-center">
            <div onClick={() => setModalCadastro(false)} className="fundo-desfocado"></div>
            <div className="flex flex-col items-center rounded-lg sombra bg-white w-[30%] max-h-[95%] overflow-auto z-20">
                <div className="flex items-center justify-center mb-5 border-b-2 border-black w-full">
                    <h1 className="mt-7 mb-3 text-[40px] font-semibold">Adicionar Usuário</h1>
                </div>

                <div className="flex flex-col w-[90%]">
                    <h1>Nome</h1>
                    <input
                        name="username"
                        value={dados.username}
                        onChange={handleChange}
                        type="text"
                        className="input_2"
                    />

                    <h1>Email</h1>
                    <input
                        name="email"
                        value={dados.email}
                        onChange={handleChange}
                        type="email"
                        className="input_2"
                    />

                    <h1>Senha</h1>
                    <input
                        name="password"
                        value={dados.password}
                        onChange={handleChange}
                        type="password"
                        className="input_2"
                    />
                    {erros.length > 0 && (
                        <div className="mt-2">
                            {erros.map((erro, index) => (
                                <div key={index} className="flex items-center text-red-500 text-xs">
                                    <PiDot color="red" className="mr-1" />
                                    {erro}
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <div className="flex justify-around w-[95%] mt-5">
                    <div className="h-full -mt-1">
                        <div className="flex flex-col items-start mt-2 gap-1">
                            <div className="flex gap-2">
                                <input
                                    type="checkbox"
                                    value="3"
                                    checked={dados.id_plano === '3'}
                                    onChange={handleChange}
                                    className="custom-checkbox"
                                />
                                <h1>Curador</h1>
                            </div>
                            <div className="flex gap-2">
                                <input
                                    type="checkbox"
                                    value="2"
                                    checked={dados.id_plano === '2'}
                                    onChange={handleChange}
                                    className="custom-checkbox"
                                />
                                <h1>Associado</h1>
                            </div>
                            <div className="flex gap-2">
                                <input
                                    type="checkbox"
                                    value="1"
                                    checked={dados.id_plano === '1'}
                                    onChange={handleChange}
                                    className="custom-checkbox"
                                />
                                <h1>Colaborador</h1>
                            </div>
                        </div>
                    </div>

                    {dados.senha !== '' && dados.username !== '' && dados.email !== '' && dados.id_plano !== '' ? (
                        <div className={`relative flex items-center justify-center ${shake ? 'shake-animation' : ''} mt-3`}>
                            <button onClick={() => cadastrar(dados)}className='botao_1 mb-10'>
                                <h1 className='text-2xl p-2'>Cadastrar-se</h1>
                            </button>
                        </div>
                    ) : (
                        <div className={`relative flex items-center justify-center mt-3`}>
                            <button disabled className='botao_1 mb-10'>
                                <h1 className='text-2xl text-gray-500 p-2'>Cadastrar-se</h1>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </main>
    )
}

export default Cadastrar