import React, { useState, useEffect, useRef } from 'react'

const DropDown = ({
    opcoes = [],
    chaveId = 'id',
    displayKey = 'nome',
    value,
    onChange,
    placeholder = 'Selecione uma opção...',
    className = 'input_2 w-full',
    onAdd,
}) => {
    const [aberto, setAberto] = useState(false)
    const [filtro, setFiltro] = useState('')
    const dropdownRef = useRef(null)

    const filteredOptions = opcoes.filter((option) =>
        option[chaveId]?.toString().toLowerCase().includes(filtro.toLowerCase())
    )    

    const handleOptionClick = (option) => {
        onChange(option)
        setAberto(false)
        setFiltro('')
    }

    useEffect(() => {
        const handleClickFora = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setAberto(false)
            }
        }

        document.addEventListener('mousedown', handleClickFora)
        return () => {
            document.removeEventListener('mousedown', handleClickFora)
        }
    }, [])

    const displayValue = value
        ? typeof value === 'object'
            ? value[displayKey]
            : opcoes.find((option) => option[chaveId] === value)?.[displayKey]
        : filtro

    return (
        <div ref={dropdownRef} className="relative w-full">
            <input
                type="text"
                value={displayValue || ''}
                onChange={(e) => setFiltro(e.target.value)}
                onFocus={() => setAberto(true)}
                placeholder={placeholder}
                className={className}
            />
            {aberto && (
                <ul className="absolute bg-white border rounded shadow-lg mt-1 w-full max-h-60 overflow-auto z-10">
                    {filteredOptions.map((option) => (
                        <li
                            key={option[chaveId]}
                            className="p-2 hover:bg-gray-200 cursor-pointer"
                            onClick={() => handleOptionClick(option[chaveId])}
                        >
                            {option[displayKey]}
                        </li>
                    ))}
                    {onAdd && (
                        <li
                            className="p-2 hover:bg-gray-200 cursor-pointer border-t"
                            onClick={() => {
                                setAberto(false)
                                onAdd(true)
                            }}
                        >
                            Adicionar Novo
                        </li>
                    )}
                    {filteredOptions.length === 0 && !onAdd && (
                        <li className="p-2 text-gray-500">Nenhuma opção encontrada</li>
                    )}
                </ul>
            )}
        </div>
    )
}

export default DropDown