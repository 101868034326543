import React, { useEffect, useState } from 'react'
import { PiCrownSimple, PiTrash } from 'react-icons/pi'
import { BiSolidDownArrow, BiSolidUpArrow } from 'react-icons/bi'
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md'
import Header from '../../interface/header'
import MenuT from '../../interface/menuT'
import Cadastro from './cadastar'
import Deletar from './deletar_conta'
import Update from './update_conta'
import { BsPersonPlus } from 'react-icons/bs'

const formatarData = (dataIso) => {
    const data = new Date(dataIso)
    const formato = Intl.DateTimeFormat("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZoneName: "short",
    })
    return formato.format(data)
}

const Contas = () => {
    const token = localStorage.getItem('key')
    const [contas, setContas] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [sortConfig, setSortConfig] = useState({ key: null, direcao: 'asc' })
    const [isAllSelected, setIsAllSelected] = useState(false)
    const [modalCadastro, setModalCadastro] = useState(false)
    const [modalDelete, setModalDelete] = useState(false)
    const [modalUpdate, setModalUpdate] = useState(false)
    const [contasSelecionadas, setContasSelecionadas] = useState([])

    const [paginaAtual, setPaginaAtual] = useState(1)
    const [itensPorPagina, setItensPorPagina] = useState(0) 

    const recalcula = () => {
        setItensPorPagina((window.innerHeight - 350) / 40)
    }

    useEffect(() => {
        receberDados() 
        recalcula()

        window.addEventListener('resize', recalcula)

        return() => {
        window.removeEventListener('resize', recalcula)
        }
    },[])

    const receberDados = async () => {
        try{
            const response = await fetch('https://transcritor-ia.com:8086/api/administracao/usuario/', {
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Token ${token}`
                },
                credentials: 'include'
            })

            if(response.ok){
                const resposta = await response.json()
                setContas(resposta)
            }
        }
        catch(error){
            console.log(error)
        }
    }

    const contasFiltradas = contas.filter(user =>
        user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.plano.toLowerCase().includes(searchTerm.toLowerCase())
    )

    const totalPaginas = Math.ceil(contasFiltradas.length / itensPorPagina)
    const indiceInicial = (paginaAtual - 1) * itensPorPagina
    const itensPaginaAtual = contasFiltradas.slice(indiceInicial, indiceInicial + itensPorPagina)

    const ordenar = (key) => {
        let direcao = 'asc'
        if (sortConfig.key === key && sortConfig.direcao === 'asc') {
            direcao = 'desc'
        }
        setSortConfig({ key, direcao })

        const sortedcontas = [...contasFiltradas].sort((a, b) => {
            if (a[key] < b[key]) return direcao === 'asc' ? -1 : 1
            if (a[key] > b[key]) return direcao === 'asc' ? 1 : -1
            return 0
        })
        setContas(sortedcontas)
    }

    const getArrowColor = (key, direction) => {
        if (sortConfig.key === key) {
            return sortConfig.direcao === direction ? 'black' : 'gray'
        }
        return 'gray'
    }

    const toggleAll = () => {
        setIsAllSelected(!isAllSelected)
        const updatedContas = contas.map(user => {
            const isSelected = !isAllSelected
            return { ...user, isSelected }
        })
        setContas(updatedContas)
        setContasSelecionadas(updatedContas.filter(user => user.isSelected))
    }

    const toggleBox = (id) => {
        const updatedContas = contas.map(user => {
            if (user.id === id) {
                const isSelected = !user.isSelected
                return { ...user, isSelected }
            }
            return user
        })
        setContas(updatedContas)
        setContasSelecionadas(updatedContas.filter(user => user.isSelected))
    }

    const paginaAnterior = () => {
        if (paginaAtual > 1) setPaginaAtual(paginaAtual - 1)
    }

    const proximaPagina = () => {
        if (paginaAtual < totalPaginas) setPaginaAtual(paginaAtual + 1)
    }

    const atualizaConta = (conta) => {
        setContasSelecionadas(conta)
        setModalUpdate(true)
    }

    const fecharUpdate = (e) => {
        // verifica se o clique foi em algo relacionado ao modalUpdate antes de mudar o estado
        const elementoModal = e.target.closest('.modal-update')
    
        if (!elementoModal) {
            setModalUpdate(false)
        }
    }
    
    return (
        <div onClick={fecharUpdate} className='flex flex-col h-[100vh]'>
            {modalCadastro &&
                <Cadastro 
                    setModalCadastro={() => {setModalCadastro()}}
                    receberDados={receberDados}
                />
            }
            {modalDelete &&
                <Deletar 
                    contasSelecionadas={contasSelecionadas}
                    setModalDeletar={() => {setModalDelete()}}
                    receberDados={receberDados}
                />
            }
            <Header perfil={true}/>
            
            <MenuT
                rotas={[{ nome: 'Coleções', link: '/coleções' }, { nome: 'Contas', link: '/admin/contas' }]}
                botoes={[]}
            />
            <div className='flex gap-3 w-full px-5 pt-5 pb-5 '>
                <div className='flex items-center px-2 border border-black rounded-lg w-full'>
                    <input
                        type="text"
                        placeholder="Buscar usuário..."
                        className="w-full p-2 focus:outline-none"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                <div className='flex gap-3'>
                    <button onClick={() => {setModalCadastro(true)}} className='botao_1 flex items-center gap-2 justify-center '>
                        <BsPersonPlus size={25} color='white'/>
                    </button>
                    <button onClick={() => {setModalDelete(true)}} className='botao_1 flex items-center gap-2 justify-center '>
                        <PiTrash size={25} color='white'/>
                    </button>
                </div>
            </div>

            <div>
                <table onClick={fecharUpdate}  className='table-auto w-full'>
                    <thead className='border-b-2 border-b-gray-400 font-semibold text-gray-600 text-xl'>
                        <tr>
                            <th className='w-[3vw] py-2'>
                                <input
                                    className='h-5 w-5 custom-checkbox'
                                    type='checkbox'
                                    checked={isAllSelected}
                                    onChange={toggleAll}
                                />
                            </th>
                            <th className='w-[5vw]'>
                                <div className='flex gap-2 items-center cursor-pointer' onClick={() => ordenar('id')}>
                                    <h1>ID</h1>
                                    <div>
                                        <BiSolidUpArrow size={15} color={getArrowColor('id', 'asc')} />
                                        <BiSolidDownArrow size={15} color={getArrowColor('id', 'desc')} />
                                    </div>
                                </div>
                            </th>
                            <th className='w-[15vw] cursor-pointer text-left pl-2'>
                                <div onClick={() => ordenar('username')} className='flex gap-2 items-center cursor-pointer'>
                                    <h1>NOME</h1>
                                    <div>
                                        <BiSolidUpArrow size={15} color={getArrowColor('username', 'asc')} />
                                        <BiSolidDownArrow size={15} color={getArrowColor('username', 'desc')} />
                                    </div>
                                </div>
                            </th>
                            <th className='w-[15vw] cursor-pointer text-left pl-2'>
                                <div onClick={() => ordenar('email')} className='flex gap-2 items-center cursor-pointer'>
                                    <h1>EMAIL</h1>
                                    <div>
                                        <BiSolidUpArrow size={15} color={getArrowColor('email', 'asc')} />
                                        <BiSolidDownArrow size={15} color={getArrowColor('email', 'desc')} />
                                    </div>
                                </div>
                            </th>
                            <th className='w-[15vw] cursor-pointer text-left pl-2'>
                                <div onClick={() => ordenar('date_joined')} className='flex gap-2 items-center cursor-pointer'>
                                    <h1>DATA-CADASTRO</h1>
                                    <div>
                                        <BiSolidUpArrow size={15} color={getArrowColor('date_joined', 'asc')} />
                                        <BiSolidDownArrow size={15} color={getArrowColor('date_joined', 'desc')} />
                                    </div>
                                </div>
                            </th>
                            <th className='w-[15vw] cursor-pointer'>
                                <div onClick={() => ordenar('plano')} className='flex gap-2 items-center justify-center cursor-pointer'>
                                    <h1>PLANO</h1>
                                    <div>
                                        <BiSolidUpArrow size={15} color={getArrowColor('plano', 'asc')} />
                                        <BiSolidDownArrow size={15} color={getArrowColor('plano', 'desc')} />
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody className='relative border-b border-b-gray-400 bg-gray-300 font-semibold text-gray-600'>
                        {itensPaginaAtual.map(user => (
                            <tr key={user.id} >
                                <td className='text-center py-2'>
                                    <input
                                        className='h-5 w-5 custom-checkbox'
                                        type='checkbox'
                                        checked={!!user.isSelected}
                                        onChange={() => toggleBox(user.id)}
                                    />
                                </td>
                                <td className='text-left truncate'>{user.id}</td>
                                <td className='text-left pl-2 truncate'>{user.username}</td>
                                <td className='text-left pl-2 truncate'>{user.email}</td>
                                <td className='text-left pl-2 truncate'>{formatarData(user.date_joined)}</td>
                                <td onClick={() => {atualizaConta(user)}} className="text-center truncate cursor-pointer modal-update">{user.plano}
                                    {modalUpdate && contasSelecionadas.id === user.id &&
                                        <Update 
                                            contaSelecionada={contasSelecionadas}
                                            setModalUpdate={() => {setModalUpdate()}}
                                            receberDados={receberDados}
                                            className="modal-update"
                                        />
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className='flex justify-between items-center py-3 px-5 border-t border-gray-400 font-semibold text-gray-600'>
                <span>
                    {itensPaginaAtual.length} de {contasFiltradas.length}
                </span>
                <div className='flex gap-2 items-center'>
                    <button 
                        className='botao_1'
                        onClick={paginaAnterior}
                        disabled={paginaAtual === 1}
                    >
                        <MdOutlineKeyboardArrowLeft size={30} />
                    </button>
                    <span className='flex'>{paginaAtual} / {totalPaginas}</span>
                    <button 
                        className='botao_1'
                        onClick={proximaPagina}
                        disabled={paginaAtual === totalPaginas}
                    >
                        <MdOutlineKeyboardArrowRight size={30}/>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Contas