import React from 'react'
import { PiDotsThreeVerticalBold } from 'react-icons/pi'
import { Link, useLocation } from 'react-router-dom'

const Pagina = ({ numero, id_imagem, imagem }) => {
    const url = useLocation()

    return (
        <Link to={`${url.pathname}/imagem/${id_imagem}`} className='flex flex-col drop-shadow-xl sombra cursor-pointer'>
            <div className='flex pt-10 pb-5 items-center justify-center bg-[#b3b7c2] font-semibold text-lg'>
                Imagem {numero}
            </div>
            <div className='flex items-center justify-between text-sm px-2 pb-3 bg-[#b3b7c2]'>
                <input 
                    className='custom-checkbox' 
                    type='checkbox'
                    onClick={(e) => e.stopPropagation()}
                />
                <PiDotsThreeVerticalBold 
                    size={25} 
                    className='cursor-pointer '
                    onClick={(e) => e.stopPropagation()}
                />
            </div>
        </Link>
    );
};

export default Pagina;
