import { useMemo } from "react"
import { useNavigate } from "react-router-dom"

const Header = ({ perfil }) => {
    const dados = JSON.parse(localStorage.getItem("user"))
    const navigate = useNavigate()

    const urlFoto = useMemo(() => dados?.picture, [dados?.picture])

    const logOut = () => {
        localStorage.clear()
        navigate("/")
    }

    return (
        <header>
            <div className="flex flex-row items-center justify-between w-full h-20 border-b-2 border-gray-300">
                <img src="/imagens/Transcritor.IA_logo.png" alt="logo" className="h-20" />
                {perfil && (
                    <div onClick={logOut} className="flex items-center h-full mr-5 cursor-pointer">
                        <h1 className="font-semibold">{dados?.name}</h1>
                        {urlFoto && (
                            <img
                                className="h-16 rounded-full ml-5"
                                alt="foto_perfil"
                                src={urlFoto}
                            />
                        )}
                    </div>
                )}
            </div>
        </header>
    )
}

export default Header