import React, { useEffect, useState } from 'react'
import {  PiTrash } from 'react-icons/pi'
import { useNavigate, useParams } from 'react-router-dom'
import Header from '../interface/header'
import MenuT from '../interface/menuT'
import Busca from '../interface/busca'
import Pagina from '../interface/imagem'
import Upload from '../interface/modais/modal_upload_pagina'
import { FiFileText } from 'react-icons/fi'
import { LuFileInput } from 'react-icons/lu'
import { LiaFileDownloadSolid } from 'react-icons/lia'

const Imagens = () => {
    const token = localStorage.getItem('key')
    const [paginas, setPaginas] = useState([])
    const [modalUpload, setModalUpload] = useState(false)
    const { id, nome } = useParams()

    const navigate = useNavigate()

    const getPaginas = async () => {
        const response = await fetch(`https://transcritor-ia.com:8086/api/livro/${id}/imagens/`,{
            method: 'GET',
            headers: {
                'accept': 'application/json',
                'Authorization': `Token ${token}`
            }
        })

        if(response.ok){
            const resposta = await response.json()
            setPaginas(resposta)
        } else{
            navigate('/coleções')
        }
    }

    useEffect(() => {
        getPaginas()
    }, [])

    return (
        <div className='flex flex-col h-[100vh]'>
            {modalUpload &&
                <Upload
                    setModalUpload={() => (setModalUpload())}
                    idLivro={id}
                    getPaginas={getPaginas}
                />
            }
            <Header perfil={true}/>
            <MenuT
                rotas={[{ nome: 'Coleções', link: '/coleções' }, { nome: nome }]}
                botoes={[
                    <Busca placeholder="Pesquise nas suas transcrições" />,
                    <button onClick={() => (setModalUpload(true))} className='botao_3' title='Adicionar Páginas'>
                        <LuFileInput size={20} />
                    </button>,
                    <button className='botao_3' title='Exportar'>
                        <LiaFileDownloadSolid size={20} />
                    </button>,
                    <button className='botao_3' title='Deletar arquivos selecionados'>
                        <PiTrash size={20} />
                    </button>
                ]}
            />
            <div className='w-full px-5 pb-5 '>
                <div className='flex flex-row items-center gap-5 mt-5'>
                    <div className='flex'>
                        <FiFileText size={25} />
                        <h1 className='mt-[2px] ml-1 font-semibold'>Páginas</h1>
                    </div>
                </div>
                <div className='grid_paginas'>
                    {paginas.map(pagina => (
                        <Pagina
                            key={pagina.id_imagem}
                            numero={pagina.numero}
                            id_imagem={pagina.id_imagem}
                            imagem={pagina.imagem}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Imagens;