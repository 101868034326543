import { useState } from 'react'

const Deletar = ({ setModalDeletar, contasSelecionadas, receberDados }) => {
    const token = localStorage.getItem('key')
    const [confirmar, setConfirmar] = useState('')

    const deletarContas = async () => {
        if (confirmar.toLowerCase() === 'deletar') {
            try {
                for (const conta of contasSelecionadas) {
                    const response = await fetch(`https://transcritor-ia.com:8086/api/administracao/usuario/${conta.id}/`, {
                        method: 'DELETE',
                        headers: {
                            'accept': 'application/json',
                            'Authorization': `Token ${token}`
                        }
                    })
                    if (response.ok) {
                        receberDados()
                    }
                }
                setModalDeletar(false)
            } catch (error) {
                console.error('Erro ao tentar deletar as contas:', error)
            }
        }
    }

    return (
        <main className='absolute h-full w-full flex items-center justify-center'>
            <div onClick={() => { setModalDeletar(false) }} className='fundo-desfocado'></div>
            <div className='flex flex-col items-center rounded-lg sombra bg-white w-[40%] z-20'>
                <div className=' flex items-center justify-center mb-3 border-b-2 border-black w-full'>
                    <h1 className='mt-7 mb-3 text-[40px] font-semibold '>Excluir usuário</h1>
                </div>

                <h1 className='mb-3 px-5 text-md font-semibold text-gray-600'>
                    Digite 'Deletar' para confirmar o procedimento.
                </h1>

                <div className='flex flex-col w-[90%]'>
                    <input
                        value={confirmar}
                        onChange={(e) => { setConfirmar(e.target.value) }}
                        type='text'
                        className='input_2'
                    />
                </div>

                <div className='flex items-center mt-3 justify-center w-[95%]'>
                    {confirmar.toLowerCase() === 'deletar' ? (
                        <button onClick={deletarContas} className='botao_1 mb-3'>
                            <h1 className='p-2'>Deletar conta</h1>
                        </button>
                    ) : (
                        <button disabled className='botao_1 mb-3'>
                            <h1 className='text-gray-400 p-2'>Deletar conta</h1>
                        </button>
                    )}
                </div>
            </div>
        </main>
    )
}

export default Deletar