import React, { useState, useRef } from 'react'
import { PiFilePdf, PiImage, PiFile } from 'react-icons/pi'
import { AiOutlineClose, AiOutlineRotateLeft, AiOutlineRotateRight } from 'react-icons/ai'
import { FaSpinner } from 'react-icons/fa'

const UploadPagina = ({ setModalUpload, idLivro, getPaginas }) => {
  const token = localStorage.getItem('key')
  const [files, setFiles] = useState([])
  const [arquivoSelecionado, setArquivoSelecionado] = useState(null)
  const [indexSelecionado, setIndexSelecionado] = useState(null)
  const [graus, setGraus] = useState({})
  const [enviando, setEnviando] = useState(false)
  const [rotacao, setRotacao] = useState(90);
  const canvasRef = useRef(null)

  const handleFileChange = (e) => {
    const arquivosSelecionados = Array.from(e.target.files)
    setFiles(arquivosSelecionados)
    const firstImageIndex = arquivosSelecionados.findIndex(file => file.type.startsWith('image/'))
    if (firstImageIndex >= 0) {
      const firstImage = arquivosSelecionados[firstImageIndex]
      const imageUrl = URL.createObjectURL(firstImage)
      setArquivoSelecionado(imageUrl)
      setIndexSelecionado(firstImageIndex)
      setGraus((prev) => ({ ...prev, [firstImageIndex]: 0 }))
    }
  }

  const removerArquivo = (index) => {
    const newFiles = [...files]
    newFiles.splice(index, 1)
    setFiles(newFiles)

    const newRotations = { ...graus }
    delete newRotations[index]
    setGraus(newRotations)

    if (indexSelecionado === index) {
      const remainingImageIndex = newFiles.findIndex(file => file.type.startsWith('image/'))
      setIndexSelecionado(remainingImageIndex)
      setArquivoSelecionado(
        remainingImageIndex >= 0 ? URL.createObjectURL(newFiles[remainingImageIndex]) : null
      )
    }
  }

  const rotacionar = (graus) => {
    if (indexSelecionado !== null) {
      setGraus((prev) => ({
        ...prev,
        [indexSelecionado]: ((prev[indexSelecionado] || 0) + graus + 360) % 360,
      }))
    }
  }

  const renderRotacionado = () => {
    const canvas = canvasRef.current
    if (!canvas || !arquivoSelecionado) return

    const ctx = canvas.getContext('2d')
    const img = new Image()
    img.src = arquivoSelecionado

    img.onload = () => {
      canvas.width = img.width
      canvas.height = img.height

      const centerX = canvas.width / 2
      const centerY = canvas.height / 2

      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.save()
      ctx.translate(centerX, centerY)
      ctx.rotate(((graus[indexSelecionado] || 0) * Math.PI) / 180)
      ctx.drawImage(img, -img.width / 2, -img.height / 2)
      ctx.restore()
    }
  }

  const getIcones = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase()
    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return <PiImage className="text-blue-500 text-xl" />
      case 'pdf':
        return <PiFilePdf className="text-red-500 text-xl" />
      default:
        return <PiFile className="text-gray-500 text-xl" />
    }
  }

  React.useEffect(() => {
    renderRotacionado()
  }, [graus, arquivoSelecionado])

  const enviarArquivos = async () => {
    if (!files.length) {
      alert('Selecione pelo menos uma imagem para enviar.')
      return
    }

    setEnviando(true)

    for (let i = 0; i < files.length; i++) {
      if (files[i].type.startsWith('image/')) {
        const imageUrl = URL.createObjectURL(files[i])
        const canvas = canvasRef.current

        setArquivoSelecionado(imageUrl) 
        setIndexSelecionado(i)

        await new Promise((resolve) => {
          renderRotacionado()
          setTimeout(resolve, 200)
        })

        if (canvas) {
          await new Promise((resolve, reject) => {
            canvas.toBlob(async (blob) => {
              const formData = new FormData()
              formData.append('arquivos', blob, `rotated-image-${i}.png`)

              try {
                const response = await fetch(`https://transcritor-ia.com:8086/api/livro/${idLivro}/transcrever/`, {
                  method: 'POST',
                  headers: {
                    accept: 'application/json',
                    Authorization: `Token ${token}`,
                  },
                  body: formData,
                })

                if (!response.ok) {
                  console.error(`Erro ao enviar a imagem rotacionada para o arquivo ${i}.`)
                }
                resolve()
              } catch (error) {
                console.error(`Erro ao enviar a imagem ${i}:`, error)
                reject(error)
              }
            })
          })
        }
      }
    }

    setEnviando(false)
    setModalUpload(false)
    getPaginas(idLivro)
  }

  return (
    <div className="absolute h-full w-full flex items-center justify-center">
      <div onClick={() => setModalUpload(false)} className="fundo-desfocado z-10"></div>
      <div className="flex flex-col items-center rounded-lg sombra bg-white min-w-[400px] px-10 max-w-[90%] max-h-[80%] z-20">
        <div className="flex gap-4 items-center justify-center bg-white w-full py-5">
          {files.length > 0 && (
            <div className="flex-grow h-[70vh] overflow-auto">
              <ul className="flex flex-col gap-2">
                {files.map((file, index) => (
                  <li
                    key={index}
                    className="flex items-center justify-between gap-2 border p-2 rounded bg-gray-100 cursor-pointer"
                    onClick={() => {
                      setArquivoSelecionado(URL.createObjectURL(file))
                      setIndexSelecionado(index)
                    }}
                  >
                    <div className="flex items-center gap-2">
                      {getIcones(file.name)}
                      <span className="truncate">{file.name}</span>
                    </div>
                    <button
                      onClick={(e) => {
                        e.stopPropagation()
                        removerArquivo(index)
                      }}
                      className="text-red-500 hover:text-red-700"
                    >
                      <AiOutlineClose className="text-xl" />
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className="flex flex-col gap-2">
            {arquivoSelecionado && (
              <>
                <div className="flex justify-center gap-4 mb-4">
                <button onClick={() => rotacionar(-rotacao)} className="botao_1">
                    <AiOutlineRotateLeft className="text-xl" />
                  </button>
                  <input
                    type="number"
                    value={rotacao}
                    onChange={(e) => setRotacao(parseInt(e.target.value) || 90)}
                    className="w-16 text-center border border-gray-300 rounded"
                  />
                  <button onClick={() => rotacionar(rotacao)} className="botao_1">
                    <AiOutlineRotateRight className="text-xl" />
                  </button>
                </div>
                <div className="flex justify-center items-center mb-4">
                  <canvas ref={canvasRef} className="border rounded max-h-[60vh] max-w-[40vw]" />
                </div>
              </>
            )}
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              className="border border-gray-300 rounded p-2"
            />
            {enviando ? (
              <button disabled className="botao_1 flex justify-center">
                <FaSpinner className="animate-spin mr-2 my-1" />
              </button>
            ) : (
              <button onClick={enviarArquivos} className="botao_1">
                Enviar
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default UploadPagina