import { IoSearchOutline } from "react-icons/io5"
import { useState } from "react"

const Busca = ({ placeholder = "Pesquise...", onSearch }) => {
    const [inputbusca, setInputbusca] = useState("")

    const handleInputChange = (event) => {
        const value = event.target.value
        setInputbusca(value)
        onSearch(value)
    }

    return (
        <div>
            <div className="relative flex items-center">
                <IoSearchOutline size={20} color="black" className="absolute ml-1"/>
                <input
                    placeholder={placeholder}
                    value={inputbusca}
                    onChange={handleInputChange}
                    className="text-black placeholder-black px-6 border-2 border-white w-[30vw] rounded-xl"
                />
            </div>
        </div>
    )
}

export default Busca
