import React from 'react'
import { CORES } from '../util/cores'
import { FaCheck, FaCircle } from 'react-icons/fa' // Importando o ícone de verificação
import { FaCircleCheck } from 'react-icons/fa6'

const LineItem = ({ linha, indexLinha, indexRegiao, validarLinha, handleFocarLinha, getDados }) => {
    const token = localStorage.getItem('key')
    const backgroundColor = linha.certificada
        ? CORES.certificada
        : linha.focado
        ? CORES.focado
        : '#e5e7eb' // cor padrão

    const certificarLinha = async (idLinha) => {
        try {
            const response = await fetch(`https://transcritor-ia.com:8086/api/livro/${idLinha}/certificar-linha/`, {
                method: 'PUT',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Token ${token}`,
                }
            })
            if (response.ok) {
                getDados()
            } else {
                console.error('Erro ao certificar a linha')
            }
        } catch (error) {
            console.error('Erro ao certificar a linha:', error)
        }
    }

    return (
        <div key={linha.id_linha + indexRegiao * 2} className='flex mx-2 gap-2 items-center'>
            <h1 className={`${indexLinha > 8 ? '' : 'mr-[2px]'} flex items-center justify-center -mr-2 mt-3`}>{indexLinha + 1}</h1>
            <div
                key={indexLinha}
                style={{ backgroundColor }}
                className='w-full flex px-2 items-center justify-center mt-3 cursor-pointer rounded-lg'
            >
                <h1 onClick={() => handleFocarLinha(linha.id_linha)} onDoubleClick={() => validarLinha(linha, indexLinha, indexRegiao)} className='w-full text-start'>
                    {linha.transcricao || `Linha ${indexLinha + 1}`}
                </h1>
                {linha.certificada ? <FaCircleCheck onClick={() => certificarLinha(linha.id_linha)} className='ml-2 cursor-pointer' /> : <FaCircle onClick={() => certificarLinha(linha.id_linha)} className='ml-2 cursor-pointer' />}
            </div>
        </div>
    )
}

export default LineItem