import { useState } from 'react'
import { FaCircle } from 'react-icons/fa'
import { FaCircleCheck } from 'react-icons/fa6'
import { PiTrash, PiX } from 'react-icons/pi'

const Validacao = ({ setModalValidar, setLinhaSelecionada, linhaSelecionada, dadosValidar, getDados }) => {
    const token = localStorage.getItem('key')
    const [erros, setErros] = useState([])
    const [certificado, setCertificado] = useState(false)
    const [dados, setDados] = useState({
        transcricao: linhaSelecionada.transcricao
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setDados((prevDados) => ({
            ...prevDados,
            [name]: value,
        }))
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleEditarTranscricao()
        }
    }

    const handleEditarTranscricao = async () => {
        try {
            const response = await fetch(`https://transcritor-ia.com:8086/api/livro/${linhaSelecionada.id_linha}/editar-transcricao/`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    transcricao: dados.transcricao,
                }),
            })

            if (response.ok) {
                getDados()
            } else {
                const errorData = await response.json()
                setErros(errorData.errors || ['Erro ao editar a transcrição.'])
            }
        } catch (error) {
            setErros(['Erro de rede ou servidor.'])
        }
    }

    const deletarLinhaApi = async (linhaId) => {
        try {
            const response = await fetch(`https://transcritor-ia.com:8086/api/livro/linha/${linhaId}/`, {
                method: 'DELETE',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Token ${token}`,
                },
            })
    
            if (response.ok) {
                fechar()
                getDados()
            } else {
                console.error(`Erro ao deletar a linha ${linhaId}:`, response.status, await response.text())
            }
        } catch (error) {
            console.error(`Erro na requisição de deletar linha ${linhaId}:`, error)
        }
    }

    const fechar = () => {
        setModalValidar()
        setLinhaSelecionada(null)
    }

    const certificarLinha = async (idLinha) => {
        try {
            const response = await fetch(`https://transcritor-ia.com:8086/api/livro/${idLinha}/certificar-linha/`, {
                method: 'PUT',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Token ${token}`,
                }
            })
            if (response.ok) {
                getDados()
            } else {
                console.error('Erro ao certificar a linha')
            }
        } catch (error) {
            console.error('Erro ao certificar a linha:', error)
        }
    }

    return (
        <main className="absolute h-full w-full flex items-center justify-center z-50">
            <div onClick={() => fechar()} className="fundo-desfocado"></div>
            <div className="flex flex-col rounded-lg sombra  border-2 border-black bg-white max-w-[90%] max-h-[95%] overflow-auto z-20 shadow-lg">
                
                <div className="flex p-2 overflow-hidden">
                    <div className="flex gap-2 w-[80%] ">
                        <h1 className="font-semibold">Página: {dadosValidar.numeroPagina} |</h1>
                        <h1 className="font-semibold">Região: {dadosValidar.numeroRegiao} |</h1>
                        <h1 className="font-semibold">Linha: {dadosValidar.numeroLinha}</h1>
                    </div>

                    <button className="botao-container mr-[-2px]" onClick={() => deletarLinhaApi(linhaSelecionada.id_linha)}>
                        <PiTrash size={30}/>
                    </button>
                    <button className="botao-container mr-[-10px]" onClick={() => fechar()}>
                        <PiX size={30}/>
                    </button>
                </div>

                <div className="p-4 space-y-4 flex-1 overflow-auto">
                    <div className="bg-gray-100 h-36 flex items-center justify-center p-4 rounded-md shadow-md">
                        <img src={`https://transcritor-ia.com:8086${linhaSelecionada.linha}`}></img>
                    </div>

                    <div className='relative'>
                        <input
                            className="input_3"
                            placeholder='Transcrição para editar'
                            value={dados.transcricao}
                            name="transcricao"
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                        />
                        {linhaSelecionada.certificada ? <FaCircleCheck size={20} onClick={() => certificarLinha(linhaSelecionada.id_linha)} className='absolute -top-3 -end-3 cursor-pointer' /> : <FaCircle size={20} onClick={() => certificarLinha(linhaSelecionada.id_linha)} className='absolute -top-3 -end-3 cursor-pointer' />}
                    </div>

                    {certificado && (
                        <div className="text-green-500 font-bold">Linha certificada!</div>
                    )}

                    {erros.length > 0 && (
                        <div className="text-red-500 font-bold">
                            {erros.map((erro, index) => (
                                <p key={index}>{erro}</p>
                            ))}
                        </div>
                    )}
                </div>

            </div>
        </main>
    )
}

export default Validacao