import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'
import Cadastro from '../interface/modais/modal_cadastro'
import Header from '../interface/header'
import { FaSpinner } from 'react-icons/fa'
import { GoogleLogin } from '@react-oauth/google'

const Homepage = () => {
    const [modalCadastro, setModalCadastro] = useState(false)
    const [logando, setLogando] = useState(false)
    const [shake, setShake] = useState(false)
    const [username, setUsername] = useState('')
    const [senha, setSenha] = useState('')

    const navigate = useNavigate()

    const login = async (username, senha) => {
        try {
            setLogando(true)
            const response = await fetch('https://transcritor-ia.com:8086/api/auth/login', {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: username,
                    password: senha
                })
            })
            setLogando(false)
            if (response.ok) {
                const resposta = await response.json()
                localStorage.setItem('key', resposta.token)
                localStorage.setItem('perm', resposta.plano)
                localStorage.setItem('user', JSON.stringify({name: resposta.username}));
                navigate('/coleções')
            } else {
                setShake(true)
                setTimeout(() => setShake(false), 500)
            }
        } catch (error) {
            console.error('Login failed', error)
        }
    }

    const loginGoogle = async (googleData) => {
        try {
            const dados = jwtDecode(googleData.credential)
            const response = await fetch('https://transcritor-ia.com:8086/api/auth/google/',{
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    sub: parseInt(dados.sub),
                    name: dados.name,
                    email: dados.email
                })
            })

            if(response.ok){
                const resposta = await response.json()
                localStorage.setItem('key', resposta.token)
                localStorage.setItem('user', JSON.stringify(dados))
                localStorage.setItem('perm', resposta.plano)
                navigate('/coleções')
            }
        } catch (error) {
            console.error("Falha no login google")
        }
    }

    useEffect(() => {
        const videoElement = document.getElementById("vid")
        try {
            videoElement.play()
        } catch (error) {
            console.log("O autoplay falhou.")
        }
    }, [])

    return (
        <div className='flex flex-col h-[100vh]'>
            {modalCadastro &&
               <Cadastro 
                setModalCadastro={() => setModalCadastro(false)}
                funcLogin={login}
               /> 
            }
            
            <Header/>

            <div className='flex items-center justify-between px-16 gap-5 h-full'>
                <div className='w-[70%] rounded-lg border-2 border-black'>
                    <video id="vid" className='rounded-md' loop muted>
                        <source src="./imagens/IMG_0852.MP4" type="video/mp4" />
                        Seu navegador não suporta de vídeos.
                    </video>
                </div>

                <div className='mr-10 relative flex flex-col items-center justify-center border-2 border-black p-5 rounded-xl shadow-2xl'>
                    <h1 className='text-4xl font-bold mb-10'>Login</h1>
                    <input value={username} onChange={(e) => setUsername(e.target.value)} className='input_1 w-full' placeholder='Login' type='text' />
                    <input value={senha} onChange={(e) => setSenha(e.target.value)} className='input_1 w-full' placeholder='Senha' type='password' />

                    {logando ? (
                        <button className='botao_1 w-[95%] flex justify-center'> <FaSpinner className="animate-spin mr-2 my-1" /> </button>       
                    ):(
                        <button onClick={() => login(username, senha)} className={`botao_1 w-[95%]  ${shake ? 'shake-animation':''} `}> Entrar </button>
                    )}

                    <h1 onClick={() => setModalCadastro(true)} className='mt-2 font-semibold cursor-pointer'>Criar conta</h1>

                    <div className='w-full'>
                        <div className='absolute h-1 w-full bg-black start-0 mt-5'>
                            <div className='flex items-center justify-center'>
                                <h1 className='-mt-5 p-2 bg-white font-bold'>ou</h1>
                            </div>
                        </div>
                        <div className='flex justify-center mt-10'>
                            <GoogleLogin 
                                onSuccess={credentialResponse => { loginGoogle(credentialResponse) }}
                                width={250}
                                shape='pill'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Homepage