import React, { useRef, useEffect, useState, useCallback } from 'react'
import { useAtalho } from '../util/atalho'
import { useAtalhoCombo } from '../util/atalho_combo_ctrl'
import { FaPenNib } from 'react-icons/fa'
import { Stage, Layer, Image, Rect, Line, Circle, Text, Group } from 'react-konva'
import { useNavigate, useParams } from 'react-router-dom'
import Validacao from '../interface/modais/modal_validacao'
import Area from '../classes/area'
import Linha from '../classes/linha'
import { handleMouseDown, handleMouseMove, handleMouseUp } from '../util/mouseHandlers'
import ListRegiao from '../interface/regiao'
import Header from '../interface/transcrição/header'
import { CORES } from '../util/cores'
import { PiSelectionPlusBold } from 'react-icons/pi'
import { HiCursorClick } from 'react-icons/hi'

const Transcricao = () => {
    const MAX_ZOOM = 3
    const MIN_ZOOM = 0.3
    const navigate = useNavigate()
    const { id, id_imagem } = useParams()
    const token = localStorage.getItem('key')
    const stageRef = useRef(null)
    const imagemRef = useRef(null)
    const [nivelZoom] = useState(1)
    const [modo, setModo] = useState("")
    const [temp, setTemp] = useState(null)
    const [linhas, setLinhas] = useState([])
    const [imagem, setImagem] = useState(null)
    const [regioes, setRegioes] = useState([])
    const [linkImg, setLinkImg] = useState(null)
    const [movendo, setMovendo] = useState(false)
    const [areaAtual, setAreaAtual] = useState([])
    const [linhaAtual, setLinhaAtual] = useState([])
    const [dadosValidar, setDadosValidar] = useState({ numeroLinha: '', numeroPagina: '', numeroRegiao: '' })
    const [hoveredPonto, setHoveredPonto] = useState(null)
    const [modalValidar, setModalValidar] = useState(false)
    const [stage, setStage] = useState({ scale: .3, x: 0, y: 0 })
    const [cantoSelecionado, setCantoSelecionado] = useState(null)
    const [linhaSelecionada, setLinhaSelecionada] = useState(null)
    const [pontoSelecionado, setPontoSelecionado] = useState(null)
    const [lastMousePosition, setLastMousePosition] = useState(null)
    const [regiaoSelecionada, setRegiaoSelecionada] = useState(null)
    const [linhaFocadaId, setLinhaFocadaId] = useState(null)
    const linhaFocadaRef = useRef(null)
    const [hoveredLinha, setHoveredLinha] = useState(null)
    const [linhasAtualizadas, setLinhasAtualizadas] = useState([])
    const undoStack = useRef([])
    const redoStack = useRef([])

    ///
    const addAcaoPilha = (action) => {
        undoStack.current.push(action)
        redoStack.current = []
    }

    const undo = async () => {
        if (undoStack.current.length === 0) return
    
        const acaoRecente = undoStack.current.pop()
        console.log('popado undo',acaoRecente)       
    
        try {
            switch (acaoRecente.tipo) {
                case 'createLinha':
                    await deletarLinhaApi(acaoRecente.data.id_linha, false)
                    redoStack.current.push(acaoRecente)
                    break
                case 'deleteLinha':
                    console.log('deleteLinha: ',acaoRecente.data)
                    const linhaCriada = await enviarLinhaParaAPI({
                        id_regiao: acaoRecente.data[0].id_regiao,
                        transcricao: "",
                        pontos: [
                            [acaoRecente.data[0].pontos[0], acaoRecente.data[0].pontos[1]],
                            [acaoRecente.data[0].pontos[2], acaoRecente.data[0].pontos[3]]
                        ],
                        numero: acaoRecente.data[0].numero || 1
                    }, false)
                    acaoRecente.data = linhaCriada
                    redoStack.current.push(acaoRecente)
                    break
                case 'updateLinha':
                    await editarLinha(acaoRecente.id_linha, acaoRecente.pontosVelhos, false)
                    break
                case 'createRegiao':
                    console.log('undo-createRegiao',acaoRecente.data)
                    await deletarRegiaoApi(acaoRecente.data[0].id_regiao, false)
                    redoStack.current.push(acaoRecente)
                    break
                case 'deleteRegiao':
                    console.log('undo-deleteRegiao: ',acaoRecente.data)
                    const areaCriada = await enviarRegiaoApi({
                        id_imagem: id_imagem,
                        pontos: [
                            [acaoRecente.data[0].superior.x, acaoRecente.data[0].superior.y],
                            [acaoRecente.data[0].inferior.x, acaoRecente.data[0].inferior.y]
                        ]
                    } ,false)
                    acaoRecente.data = areaCriada
                    redoStack.current.push(acaoRecente)
                    break
                case 'updateRegiao':
                    await editarRegiao(acaoRecente.id_regiao, acaoRecente.pontosVelhos, false)
                    redoStack.current.push(acaoRecente)
                    break
                default:
                    break
            }
        } catch (error) {
            console.error('Erro ao executar undo:', error)
            redoStack.current.push(acaoRecente) // recoloca a ação na pilha de redo em caso de erro
        }
    }
    
    const redo = async () => {
        if (redoStack.current.length === 0) return
        const acaoRecente = redoStack.current.pop()
        console.log('popado redo ',acaoRecente)
    
        try {
            switch (acaoRecente.tipo) {
                case 'createLinha':
                    const linhaCriada = await enviarLinhaParaAPI({
                        id_regiao: acaoRecente.data[0].id_regiao,
                        transcricao: "",
                        pontos: [
                            [acaoRecente.data[0].pontos[0][0], acaoRecente.data[0].pontos[0][1]],
                            [acaoRecente.data[0].pontos[1][0], acaoRecente.data[0].pontos[1][1]]
                        ],
                        numero: acaoRecente.data[0].numero || 1
                    }, false)
                    acaoRecente.data = linhaCriada
                    redoStack.current.push(acaoRecente)
                    break
                case 'deleteLinha':
                    await deletarLinhaApi(acaoRecente.data[0].id_linha, false)
                    undoStack.current.push(acaoRecente)
                    break
                case 'updateLinha':
                    await editarLinha(acaoRecente.id_linha, acaoRecente.novosPontos, false)
                    undoStack.current.push(acaoRecente)
                    break
                case 'createRegiao':
                    console.log('redo-createLinha: ',acaoRecente.data.pontos)
                    const regiaoCriada = await enviarRegiaoApi({
                        id_imagem: id_imagem,
                        pontos: [
                            [acaoRecente.data[0].pontos[0][0], acaoRecente.data[0].pontos[0][1]],
                            [acaoRecente.data[0].pontos[1][0], acaoRecente.data[0].pontos[1][1]]
                        ]
                    }, false)
                    acaoRecente.data = regiaoCriada
                    undoStack.current.push(acaoRecente)
                    break
                case 'deleteRegiao':
                    console.log('redo-deleteRegiao: ',acaoRecente.data)
                    await deletarRegiaoApi(acaoRecente.data[0].id_regiao, false)
                    setRegioes((prev) => prev.filter((regiao) => regiao.id_regiao !== acaoRecente.data.id_regiao))
                    undoStack.current.push(acaoRecente)
                    break
                case 'updateRegiao':
                    await editarRegiao(acaoRecente.id_regiao, acaoRecente.novosPontos, false)
                    undoStack.current.push(acaoRecente)
                    break
                default:
                    break
            }
        } catch (error) {
            console.error('Erro ao executar redo:', error)
            redoStack.current.push(acaoRecente) // recoloca a ação na pilha de redo em caso de erro
        }
    }
    
    ///
    const getDados = async () => {
        const response = await fetch(`https://transcritor-ia.com:8086/api/livro/${id_imagem}/transcricao/`, {
            method: 'GET',
            headers: {
                'accept': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
    
        if (response.ok) {
            const resposta = await response.json()
    
            const imageResponse = await fetch(`https://transcritor-ia.com:8086/api/livro/${id}/imagens/`, {
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Token ${token}`
                }
            })
    
            if (imageResponse.ok) {
                const images = await imageResponse.json()
                images.map((image) => {
                    if (parseInt(image.id_imagem) === parseInt(id_imagem)) {
                        setLinkImg(image.imagem)
                    }
                })
            }
    
            setRegioes([])
            setLinhas([])
    
            for (let i = 0; i < resposta.length; i++) {
                const regiao = resposta[i]?.regiao;
                if (regiao && regiao.pontos && regiao.pontos.length >= 2) {
                    setRegioes(prevRegioes => [
                        ...prevRegioes,
                        new Area(
                            { x: regiao.pontos[0][0], y: regiao.pontos[0][1] },
                            { x: regiao.pontos[1][0], y: regiao.pontos[1][1] },
                            false,
                            regiao.id_regiao,
                            regiao.id_imagem,
                            regiao.numero
                        )
                    ])
                }
            
                const linhas = resposta[i]?.linhas;
                if (linhas) {
                    for (let j = 0; j < linhas.length; j++) {
                        const linha = linhas[j];
                        if (linha && linha.pontos && linha.pontos.length >= 2) {
                            const novaLinha = new Linha(
                                [linha.pontos[0][0], linha.pontos[0][1],
                                 linha.pontos[1][0], linha.pontos[1][1]],
                                linha.transcricao,
                                false,
                                linha.id_linha,
                                linha.id_regiao,
                                linha.numero,
                                linha.certificada,
                                linha.linha
                            );
            
                            setLinhas(prevLinhas => [
                                ...prevLinhas,
                                novaLinha
                            ]);
                        }
                    }
                }
            }
    
            if (linhaFocadaId) {
                handleFocarLinha(linhaFocadaId)
            }
    
        } else {
            navigate('/coleções')
        }
    }

    const renderizarImagem = useCallback(() => {
        const img = new window.Image()
        img.src = `https://transcritor-ia.com:8086${linkImg}`

        img.onload = () => {
            setImagem(img)
        }
    }, [linkImg])

    useEffect(() => {
        if (linkImg) {
            renderizarImagem()
        }
    }, [linkImg, renderizarImagem])

    useEffect(() => {
        getDados()
        renderizarImagem()
    }, [])

    useEffect(() => {
        const preventContextMenu = (e) => e.preventDefault()
        document.addEventListener('contextmenu', preventContextMenu)
        return () => document.removeEventListener('contextmenu', preventContextMenu)
    }, [])

    // funções de desenho - linhas
    const handleDesenhoStart = (pos) => {
        Linha.handleDesenhoStart(pos, transformCoords, setLinhaAtual)
    }

    const handleDesenho = (pos) => {
        Linha.handleDesenho(pos, transformCoords, linhaAtual, setLinhaAtual)
    }
    
    const handleDesenhoFim = () => {
        Linha.handleDesenhoFim(linhaAtual, linhas, regioes, setLinhas, enviarLinhaParaAPI, setLinhaAtual)
    }  
     
    const handleSelecionarLinha = (pos) => {
        const { x, y } = transformCoords(pos.x, pos.y)

        linhas.forEach((linha, index) => {
            const pontoProximo = linha.detectarProximidade(x, y)
            if (pontoProximo !== null) {
                setLinhaSelecionada([linha, index])
                setPontoSelecionado(pontoProximo)
                setTemp(linha.pontos)
            }
        })
    }

    const handleModificacaoLinha = (pos) => {
        const { x, y } = transformCoords(pos.x, pos.y)
    
        setLinhas((prevLinhas) =>
            prevLinhas.map((linha, index) => {
                if (index === linhaSelecionada[1]) {
                    const novosPontos =
                        pontoSelecionado === 0
                            ? [x, y, linha.pontos[2], linha.pontos[3]]
                            : [linha.pontos[0], linha.pontos[1], x, y]
    
                    linha.atualizarPontos(novosPontos, regioes)
    
                    
                }
                return linha
            })
        )
    }

    const enviarLinhaParaAPI = async (linha, novoMod = true) => {
        console.log('enviarLinhaParaAPI:', linha)
        try {
            const response = await fetch('https://transcritor-ia.com:8086/api/livro/linha/', {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(linha)
            })
    
            if (response.ok) {
                const linha = await response.json()
                if(novoMod){
                    addAcaoPilha({ tipo: 'createLinha', data: linha })
                }
                getDados()

                return {certificada: linha.certificada, id_linha: linha.id_linha, id_regiao: linha.id_regiao, numero: linha.numero, pontos: [linha.pontos[0][0], linha.pontos[0][1], linha.pontos[1][0], linha.pontos[1][1]], transcricao: linha.transcricao}
            } else {
                console.error('Erro na resposta da API:', await response.text())
            }
        } catch (error) {
            console.error('Erro ao criar linha:', error)
        }
    }

    const deletarLinhaApi = async (linhaId, novoMod = true) => {
        try {
            const response = await fetch(`https://transcritor-ia.com:8086/api/livro/linha/${linhaId}/`, {
                method: 'DELETE',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Token ${token}`,
                },
            })
    
            if (response.ok) {
                if(novoMod){
                    addAcaoPilha({ tipo: 'deleteLinha', data: linhas.filter((linha) => linha.focado) })
                }
                getDados()
            } else {
                console.error(`Erro ao deletar a linha ${linhaId}:`, response.status, await response.text())
            }
        } catch (error) {
            console.error(`Erro na requisição de deletar linha ${linhaId}:`, error)
        }
    }

    const editarLinha = async (linhaId, novosPontos, novoMod = true) => {
        try {
            const response = await fetch(`https://transcritor-ia.com:8086/api/livro/${linhaId}/editar-segmentacao/`,{
                method: 'PUT',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    pontos: [
                        [novosPontos[0], novosPontos[1]],
                        [novosPontos[2], novosPontos[3]]
                    ],
                    id_linha: linhaId,
                }),
            })
    
            if (response.ok) {
                if(novoMod){
                    addAcaoPilha({ tipo: 'update', pontosVelhos: temp, novosPontos, id_linha: linhaId })
                }
                getDados()
            } else {
                console.error("Erro ao editar a linha:", response.status, await response.text())
            }
        } catch (error) {
            console.error("Erro na requisição de edição da linha:", error)
        }
    }
    
    // funções de desenho - região
    const handleSelecionarRegiao = (pos) => {
        Area.handleSelecionarRegiao(pos, transformCoords, regioes, setRegiaoSelecionada, setCantoSelecionado)
    }
    
    const handleModificacaoRegiao = (pos) => {
        const regiao = regioes[regiaoSelecionada]
        regiao.handleModificacaoRegiao(pos, transformCoords, setRegioes, regiaoSelecionada, cantoSelecionado, atualizarLinhasDentroRegiao)
    }
    
    const atualizarLinhasDentroRegiao = (indexRegiao, regiaoAtualizada) => {
        const regiaoAntiga = regioes[indexRegiao]
        const novasLinhas = []
    
        setLinhas((prevLinhas) =>
            prevLinhas.map((linha) => {
                const estaDentroRegiaoAntiga = regiaoAntiga.contemLinha(linha.pontos)
                const estaDentroRegiaoNova = regiaoAtualizada.contemLinha(linha.pontos)
    
                if (estaDentroRegiaoAntiga || estaDentroRegiaoNova) {
                    const pontosAjustados = regiaoAtualizada.limitarPontos(linha.pontos)
    
                    if (
                        pontosAjustados[0] !== linha.pontos[0] ||
                        pontosAjustados[1] !== linha.pontos[1] ||
                        pontosAjustados[2] !== linha.pontos[2] ||
                        pontosAjustados[3] !== linha.pontos[3]
                    ) {
                        const novaLinha = new Linha(
                            pontosAjustados,
                            linha.transcricao,
                            false,
                            linha.id_linha,
                            linha.id_regiao,
                            linha.numero,
                            linha.certificada,
                            linha.linha
                        )
                        novasLinhas.push(novaLinha)
                        return novaLinha
                    }
                }
                return linha
            })
        )
    
        setLinhasAtualizadas(novasLinhas)
    }
     
    const handleCriacaoAreaStart = (pos) => {
        Area.handleCriacaoAreaStart(pos, transformCoords, setAreaAtual)
    }
    
    const handleCriacaoAreaMove = (pos) => {
        Area.handleCriacaoAreaMove(pos, transformCoords, areaAtual, setAreaAtual)
    }

    const handleCriacaoAreaFim = () => {
        Area.handleCriacaoAreaFim(areaAtual, setAreaAtual, regioes, setRegioes, enviarRegiaoApi, regiaoSelecionada)
    }

    const enviarRegiaoApi = async (regiao, novoMod = true) => {
        console.log('enviarRegiaoApi: ', regiao)
        
        let pontos = []

        try{
            pontos = [
                [regiao[0].x, regiao[0].y],
                [regiao[1].x, regiao[1].y]
            ]
        }catch{
            pontos = regiao.pontos
        }
        
        try {
            const response = await fetch('https://transcritor-ia.com:8086/api/livro/regiao/', {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
                body: JSON.stringify({
                    id_imagem: id_imagem,
                    pontos: pontos
                })
            })

            if (response.ok) {
                const regiao = await response.json()
                if(novoMod){
                    addAcaoPilha({ tipo: 'createRegiao', data: [regiao] })
                }
                getDados()
                console.log('regiao criada:', [{
                    superior: { x: regiao.pontos[0][0], y: regiao.pontos[0][1] },
                    inferior: { x: regiao.pontos[1][0], y: regiao.pontos[1][1] },
                    id_regiao: regiao.id_regiao,
                    numero: regiao.numero}])
                return [{
                    superior: { x: regiao.pontos[0][0], y: regiao.pontos[0][1] },
                    inferior: { x: regiao.pontos[1][0], y: regiao.pontos[1][1] },
                    id_regiao: regiao.id_regiao,
                    numero: regiao.numero
                }]
            } else {
                console.error('Erro na resposta da API:', await response.text())
            }
        } catch (error) {
            console.error('Erro ao atualizar a região:', error)
        }
    }

    const deletarRegiaoApi = async (id_regiao, novoMod = true) => {
      try {
        const response = await fetch(`https://transcritor-ia.com:8086/api/livro/regiao/${id_regiao}/`, {
          method: 'DELETE',
          headers: {
            accept: 'application/json',
            Authorization: `Token ${token}`,
          },
        })
        if (response.ok) {
            if(novoMod){
                addAcaoPilha({ tipo: 'deleteRegiao', data: regioes.filter((regiao) => regiao.focado) })
            }
            getDados()
        } else {
          console.error('Erro ao deletar a região:', await response.text())
        }
      } catch (error) {
        console.error('Erro na requisição de deleção da região:', error)
      }
    }

    const editarRegiao = async (regiaoSelecionada, novoMod = true) => {
        const regiao = regioes[regiaoSelecionada]
        try {
            const response = await fetch(`https://transcritor-ia.com:8086/api/livro/regiao/${regiao.id_regiao}/`, {
                method: 'PUT',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
                body: JSON.stringify({
                    id_imagem: id_imagem,
                    pontos: [
                        [regiao.superior.x, regiao.superior.y],
                        [regiao.inferior.x, regiao.inferior.y]
                    ]
                })
            })
    
            if (response.ok) {
                
                atualizarLinhasDentroRegiao(regiaoSelecionada, regiao)
                linhasAtualizadas.forEach(async (linha) => {
                    await editarLinha(linha.id_linha, linha.pontos, false)
                })
            } else {
                console.error('Erro ao editar a região:', await response.text())
            }
        } catch (error) {
            console.error('Erro ao editar a região:', error)
        }
    }  

    // funções utilitarias
    const handleFocarLinha = (id) => {
        setLinhas((prevLinhas) =>
            prevLinhas.map((linha) => {
                if (linha.id_linha === id) {
                    linha.focado = true
                } else {
                    linha.focado = false
                }
                return linha
            })
        )
        setLinhaFocadaId(id)
        setRegioes((prevRegioes) =>
            prevRegioes.map((regiao) => {
                regiao.focado = false
                return regiao
            })
        )
    }

    const handleFocarLinhaRegiao = (id) => { 
        setRegioes((prevRegioes) =>
            prevRegioes.map((regiao) => {
                if (regiao.id_regiao === id) {
                    regiao.focado = true
                } else {
                    regiao.focado = false
                }
                return regiao
            })
        )
        setLinhaFocadaId(null)
        setLinhas((prevLinhas) =>
            prevLinhas.map((linha) => {
                linha.focado = false
                return linha
            })
        )
    }    
    
    const handleZoom = (e) => {
        e.evt.preventDefault()
        const multiplicadorZoom = 1.05
        const stage = e.target.getStage()
        const zoomAntigo = stage.scaleX()
        const posMouse = {
            x: stage.getPointerPosition().x / zoomAntigo - stage.x() / zoomAntigo,
            y: stage.getPointerPosition().y / zoomAntigo - stage.y() / zoomAntigo
        }

        let novoZoom = e.evt.deltaY < 0 ? zoomAntigo * multiplicadorZoom : zoomAntigo / multiplicadorZoom
        novoZoom = Math.max(MIN_ZOOM, Math.min(MAX_ZOOM, novoZoom))

        const novaPosX = (stage.getPointerPosition().x / novoZoom - posMouse.x) * novoZoom
        const novaPosY = (stage.getPointerPosition().y / novoZoom - posMouse.y) * novoZoom

        setStage({
            scale: novoZoom,
            x: novaPosX,
            y: novaPosY
        })
    }

    const transformCoords = (x, y) => {
        const stage = stageRef.current
        const scale = stage.scaleX()
        const stageX = stage.x()
        const stageY = stage.y()

        return {
            x: (x - stageX) / scale,
            y: (y - stageY) / scale
        }
    }
    
    const deletarSelecionados = () => {        
        setLinhas((prevLinhas) => {
            const linhasRestantes = []
            prevLinhas.forEach((linha) => {
                if (linha.focado) {
                    deletarLinhaApi(linha.id_linha)
                } else {
                    linhasRestantes.push(linha)
                }
            })
            return linhasRestantes
        })
    
        setRegioes((prevRegioes) => {
            const regioesRestantes = []
            prevRegioes.forEach((regiao) => {
                if (regiao.focado) {
                    deletarRegiaoApi(regiao.id_regiao)
                } else {
                    regioesRestantes.push(regiao)
                }
            })
            return regioesRestantes
        })
    }

    const validarLinha = (linha, indexLinha, indexRegiao) => {
        setDadosValidar({
            numeroLinha: indexLinha + 1,
            numeroPagina: id_imagem,            
            numeroRegiao: indexRegiao + 1
        })
        setLinhaSelecionada(linha)
        setModalValidar(true)
    }

    const reset = () => {
        setModo('')
        setLinhaFocadaId(null)
        setRegioes((prevRegioes) =>
            prevRegioes.map((regiao) => {
                regiao.focado = false
                return regiao
            })
        )
        setLinhas((prevLinhas) =>
            prevLinhas.map((linha) => {
                linha.focado = false
                return linha
            })
        )
    }

    useAtalho('f', () => setModo('desenho'))
    useAtalho('Escape', () => reset())
    useAtalho('a', () => setModo('area'))
    useAtalho('Delete', () => deletarSelecionados())
    useAtalhoCombo('z', undo)
    useAtalhoCombo('y', redo)

    useEffect(() => {
        if (linkImg) {
            renderizarImagem()
        }
        const stage = stageRef.current
        const img = imagemRef.current
        if (stage && img) {
            const stageWidth = stage.width()
            const stageHeight = stage.height()
            const imgWidth = img.width()
            const imgHeight = img.height()
            const x = (stageWidth - imgWidth * stage.scaleX()) / 2
            const y = (stageHeight - imgHeight * stage.scaleY()) / 2
            setStage((prevStage) => ({
                ...prevStage,
                x: x,
                y: y
            }))
        }
    }, [linkImg, renderizarImagem])

    return (
        <div className='flex flex-col h-full w-full'>
            <Header />
            {modalValidar && (
                <Validacao
                    setModalValidar={setModalValidar}
                    setLinhaSelecionada={setLinhaSelecionada}
                    linhaSelecionada={linhaSelecionada}
                    dadosValidar={dadosValidar}
                    getDados={getDados}
                />
            )}
            <div className='flex h-[94vh]'>
                <div className='bg-gray-400 p-3 flex flex-col gap-5 z-10'>
                    <button onClick={() => setModo('')}><HiCursorClick size={30} /></button>
                    <button onClick={() => setModo('desenho')}><FaPenNib size={25} className='rotate-90 ml-1' /></button>
                    <button onClick={() => setModo('area')}><PiSelectionPlusBold size={30} /></button>
                </div>
                <div className='h-full w-[70%] bg-gray-200 z-0'>
                    <Stage
                        ref={stageRef}
                        width={window.innerWidth * 0.7}
                        height={window.innerHeight * 0.94}
                        onWheel={handleZoom}
                        onMouseDown={(e) => handleMouseDown(e, stageRef, setMovendo, setLastMousePosition, modo, handleCriacaoAreaStart, handleDesenhoStart, handleSelecionarLinha, handleSelecionarRegiao)}
                        onMouseMove={() => handleMouseMove(stageRef, movendo, lastMousePosition, setStage, setLastMousePosition, modo, handleCriacaoAreaMove, handleDesenho, linhaSelecionada, regiaoSelecionada, handleModificacaoLinha, handleModificacaoRegiao)}
                        onMouseUp={(e) => handleMouseUp(e, setMovendo, setLastMousePosition, modo, handleCriacaoAreaFim, handleDesenhoFim, linhaSelecionada, editarLinha, setLinhaSelecionada, setPontoSelecionado, regiaoSelecionada, setRegiaoSelecionada, setCantoSelecionado, editarRegiao)}
                        scaleX={stage.scale}
                        scaleY={stage.scale}
                        x={stage.x}
                        y={stage.y}
                        style={{
                            cursor: movendo ? 'grabbing' : modo === '' ? 'default' : 'crosshair'
                        }}
                    >
                        {imagem && (
                            <Layer>
                                <Image
                                    image={imagem}
                                    ref={imagemRef}
                                    width={imagem.width * nivelZoom}
                                    height={imagem.height * nivelZoom}
                                />
                                {regioes.map((regiao, index) => (
                                    <React.Fragment key={index}>
                                        <Rect
                                            onClick={() => handleFocarLinhaRegiao(regiao.id_regiao)}
                                            x={regiao.superior.x}
                                            y={regiao.superior.y}
                                            width={regiao.inferior.x - regiao.superior.x}
                                            height={regiao.inferior.y - regiao.superior.y}
                                            stroke={regiao.focado ? CORES.focado : CORES.padrao}
                                        />
                                        {regiao.focado && (
                                            <>
                                                <Circle
                                                    x={regiao.superior.x}
                                                    y={regiao.superior.y}
                                                    radius={hoveredPonto === `${index}-superior` ? 8 : 6}
                                                    fill={'white'}
                                                    stroke={'black'}
                                                    onMouseEnter={() => setHoveredPonto(`${index}-superior`)}
                                                    onMouseLeave={() => setHoveredPonto(null)}
                                                    onMouseDown={() => {
                                                        setRegiaoSelecionada(index)
                                                        setCantoSelecionado('superior')
                                                    }}
                                                />
                                                <Circle
                                                    x={regiao.inferior.x}
                                                    y={regiao.inferior.y}
                                                    radius={hoveredPonto === `${index}-inferior` ? 8 : 6}
                                                    fill={'white'}
                                                    stroke={'black'}
                                                    onMouseEnter={() => setHoveredPonto(`${index}-inferior`)}
                                                    onMouseLeave={() => setHoveredPonto(null)}
                                                    onMouseDown={() => {
                                                        setRegiaoSelecionada(index)
                                                        setCantoSelecionado('inferior')
                                                    }}
                                                />
                                                <Circle
                                                    x={regiao.superior.x}
                                                    y={regiao.inferior.y}
                                                    radius={hoveredPonto === `${index}-superiorXInferiorY` ? 8 : 6}
                                                    fill={'white'}
                                                    stroke={'black'}
                                                    onMouseEnter={() => setHoveredPonto(`${index}-superiorXInferiorY`)}
                                                    onMouseLeave={() => setHoveredPonto(null)}
                                                    onMouseDown={() => {
                                                        setRegiaoSelecionada(index)
                                                        setCantoSelecionado('superiorXInferiorY')
                                                    }}
                                                />
                                                <Circle
                                                    x={regiao.inferior.x}
                                                    y={regiao.superior.y}
                                                    radius={hoveredPonto === `${index}-inferiorXSuperiorY` ? 8 : 6}
                                                    fill={'white'}
                                                    stroke={'black'}
                                                    onMouseEnter={() => setHoveredPonto(`${index}-inferiorXSuperiorY`)}
                                                    onMouseLeave={() => setHoveredPonto(null)}
                                                    onMouseDown={() => {
                                                        setRegiaoSelecionada(index)
                                                        setCantoSelecionado('inferiorXSuperiorY')
                                                    }}
                                                />
                                            </>
                                        )}
                                    </React.Fragment>
                                ))}
                                {regioes.map((regiao) => (
                                    linhas.filter((linha) => regiao.contemLinha(linha.pontos)).map((linha, index) => (
                                        <React.Fragment key={`${regiao.id}-${index}`}>
                                            {linha.focado && (
                                                <Line
                                                    points={[
                                                        linha.pontos[0], linha.pontos[1] - 64,
                                                        linha.pontos[2], linha.pontos[3] - 64,
                                                        linha.pontos[2], linha.pontos[3] + 10,
                                                        linha.pontos[0], linha.pontos[1] + 10
                                                    ]}
                                                    closed
                                                    fill="rgba(255, 255, 204, 0.5)"
                                                />
                                            )}
                                            <Text
                                                x={index + 1 > 9 ? linha.pontos[0] - 50 : linha.pontos[0] - 25}
                                                y={linha.pontos[1] - 20}
                                                text={`${linha.numero}`}
                                                fill={linha.certificada ? CORES.certificada : linha.focado ? CORES.focado : CORES.padrao}
                                                stroke={'black'}
                                                fontSize={40}
                                            />
                                            <Line
                                                onMouseEnter={() => setHoveredLinha(`${linha.id_linha}`)}
                                                onMouseLeave={() => setHoveredLinha(null)}
                                                onMouseDown={() => handleFocarLinha(linha.id_linha)}
                                                points={linha.pontos}
                                                strokeWidth={hoveredLinha === `${linha.id_linha}` && !linha.focado ? 6 : 4}
                                                lineCap='round'
                                                stroke={linha.certificada ? CORES.certificada : linha.focado ? CORES.focado : CORES.padrao}
                                            />
                                            <Group onMouseDown={() => handleFocarLinha(linha.id_linha)}>
                                                <Circle
                                                    x={linha.pontos[0]}
                                                    y={linha.pontos[1]}
                                                    radius={hoveredPonto === `${index}-start` ? 8 : 4}
                                                    fill={'white'}
                                                    stroke={'black'}
                                                    onMouseEnter={() => setHoveredPonto(`${index}-start`)}
                                                    onMouseLeave={() => setHoveredPonto(null)}
                                                />
                                                <Circle
                                                    x={linha.pontos[2]}
                                                    y={linha.pontos[3]}
                                                    radius={hoveredPonto === `${index}-end` ? 8 : 4}
                                                    fill={'white'}
                                                    stroke={'black'}
                                                    onMouseEnter={() => setHoveredPonto(`${index}-end`)}
                                                    onMouseLeave={() => setHoveredPonto(null)}
                                                />
                                            </Group>
                                        </React.Fragment>
                                    ))
                                ))}
                                {linhaAtual.length > 0 && (
                                    <Line 
                                        points={linhaAtual}
                                        stroke={CORES.padrao}
                                    />
                                )}
                                {areaAtual.length === 2 && (
                                    <Rect
                                        x={Math.min(areaAtual[0].x, areaAtual[1].x)}
                                        y={Math.min(areaAtual[0].y, areaAtual[1].y)}
                                        width={Math.abs(areaAtual[1].x - areaAtual[0].x)}
                                        height={Math.abs(areaAtual[1].y - areaAtual[0].y)}
                                        stroke="blue"
                                        strokeWidth={2}
                                        dash={[4, 4]}
                                    />
                                )}
                            </Layer>
                        )}
                    </Stage>
                </div>
                <ListRegiao
                    regioes={regioes}
                    linhas={linhas}
                    validarLinha={validarLinha}
                    linhaFocadaId={linhaFocadaId}
                    linhaFocadaRef={linhaFocadaRef}
                    handleFocarLinha={handleFocarLinha}
                    getDados={getDados}
                />
            </div>

        </div>
    )
}

export default Transcricao