import React from 'react';
import { Link } from 'react-router-dom';

const MenuT = ({ rotas, botoes }) => {

    return (
        <div className='flex items-center min-h-[64px] justify-between py-3 bg-black text-white px-5'>
            <div className='flex h-full gap-3 font-semibold'>
                {rotas.map((rota, index) => (
                    <div className='flex items-center gap-3' key={index}>
                        {index > 0 && (
                            <div className='h-[185%] w-[2px] bg-white'></div>
                        )}
                        <div className='flex items-center'>
                            {rota.link ? 
                                (
                                    <Link className='text-xl' to={rota.link}>{rota.nome}</Link>
                                ):(
                                    <h1 className='text-xl cursor-default'>{rota.nome}</h1>
                                )
                            }
                        </div>
                    </div>
                ))}
            </div>
            <div className='flex items-center gap-3'>
                {botoes.map((botao, index) => (
                    <div key={index}>
                        {botao}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MenuT;