import React, { useEffect } from 'react'
import Linha from './linha'

const ListRegiao = ({ regioes, linhas, validarLinha, linhaFocadaId, linhaFocadaRef, handleFocarLinha, getDados }) => {
    useEffect(() => {
        if (linhaFocadaRef.current) {
            linhaFocadaRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }, [linhaFocadaId])

    return (
        <div className='h-[100%] w-[30%] z-0 overflow-auto pb-5'>
            {regioes.map((regiao, indexRegiao) => (
                <div key={indexRegiao}>
                    <div className='relative'>
                        <div className='w-full h-1 bg-black mt-4' />
                        <div className='absolute flex items-center justify-center w-full -mt-4'>
                            <h1 className='bg-white px-2'>
                                Região {indexRegiao + 1}
                            </h1>
                        </div>
                    </div>
                    {regiao.contemLinha && linhas.filter((linha) => regiao.contemLinha(linha.pontos)).map((linha, indexLinha) => (
                        <div
                            key={linha.id_linha + indexRegiao * 2}
                            ref={linha.id_linha === linhaFocadaId ? linhaFocadaRef : null}
                        >
                            <Linha
                                linha={linha}
                                indexLinha={indexLinha}
                                indexRegiao={indexRegiao}
                                validarLinha={validarLinha}
                                handleFocarLinha={handleFocarLinha}
                                getDados={getDados}
                            />
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
}

export default ListRegiao