import { IoIosArrowBack } from "react-icons/io"
import { PiArrowCircleLeft } from "react-icons/pi"
import { useNavigate } from "react-router-dom"

const Header = () => {
    const navigate = useNavigate()

    return (
        <header>
            <div className='flex flex-row items-center justify-between w-full h-[6vh] bg-black'>
                <button onClick={() => navigate(-1)} className="text-white flex items-center border-2 border-white px-2 py-1 font-semibold ml-4 rounded-lg">
                    <IoIosArrowBack color="white" />
                    Voltar
                </button>
            </div>
        </header>
    )
}

export default Header