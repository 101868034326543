import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../interface/header'
import MenuT from '../interface/menuT'
import Busca from '../interface/busca'
import Livro from '../interface/livro'
import Wizzard from '../interface/modais/modal_wizard_livro'
import { IoPersonAddOutline } from 'react-icons/io5'
import { LuFileInput } from 'react-icons/lu'

const Colecoes = () => {
    const token = localStorage.getItem('key')
    const [nivelPerm, setNivelPerm] = useState()
    const [livros, setLivros] = useState([])
    const [livrosFiltrados, setLivrosFiltrados] = useState([])
    const [modalWizzard, setModalWizzard] = useState(false)
    const [busca, setBusca] = useState("")
    const navigate = useNavigate()

    const getLivros = async () => {
        try {
            const response = await fetch('https://transcritor-ia.com:8086/api/livro/', {
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Token ${token}`
                }
            })

            if (response.ok) {
                const resposta = await response.json()
                setLivros(resposta)
                setLivrosFiltrados(resposta)
            }
        } catch (error) {
            console.log('erro ao tentar obter livros', error)
        }
    }

    useEffect(() => {
        getLivros()
        setNivelPerm(localStorage.getItem('perm'))
    }, [])

    useEffect(() => {
        const resultados = livros.filter(livro =>
            livro.nome.toLowerCase().includes(busca.toLowerCase())
        )
        setLivrosFiltrados(resultados)
    }, [busca, livros])

    const handleBusca = (valor) => {
        setBusca(valor)
    }

    return (
        <div className='flex flex-col h-[100vh]'>
            {modalWizzard &&
                <Wizzard 
                    setModalWizzard={() => (setModalWizzard())}
                    getLivros={() => getLivros()}
                />
            }
            <Header perfil={true} />
            <MenuT
                rotas={[{ nome: 'Coleções' }]}
                botoes={[<Busca placeholder="Pesquise nas suas transcrições" onSearch={handleBusca} />]}
            />
            <div className='w-full px-5 pt-5 pb-5 '>
                <div className='flex justify-between font-bold text-xl'>
                    <h1></h1>
                    <div className='flex gap-5'>
                        {nivelPerm !== 'colaborador' &&
                            <button onClick={() => (setModalWizzard(true))} title='Novo livro' className='botao_3 flex items-center justify-center'>
                                <LuFileInput size={20} />
                            </button>
                        }
                        {nivelPerm !== 'colaborador' && nivelPerm !== 'associado' && nivelPerm !== 'curador' &&
                            <button title='Gerenciar Usuários' onClick={() => { navigate('/admin/contas') }} className='botao_3 flex items-center justify-center text-base'>
                                <IoPersonAddOutline size={20} />
                            </button>
                        }
                    </div>
                </div>
                <div className='pl-5 grid_livro'>
                    {livrosFiltrados.map(livro => (
                        <Livro
                            key={livro.id_livro}
                            id={livro.id_livro}
                            nome={livro.nome}
                            local={livro.local}
                            periodoInicio={livro.periodo_inicial}
                            periodoFim={livro.periodo_final}
                            escrivao={livro.escrevente}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Colecoes
