import React from 'react'
import { PiDotsThreeVerticalBold } from 'react-icons/pi'
import { Link } from 'react-router-dom'

const formatUrl = (text) => text.toLowerCase().replace(/\s+/g, '-')

const formatDateBR = (date) => {
    const [year, month, day] = date.split('-')
    return `${day}/${month}/${year}`
}

const Livro = ({ id, nome, local, periodoInicio, periodoFim, escrivao }) => {
    const nomeUrl = formatUrl(nome)
    const periodoInicioBR = formatDateBR(periodoInicio)
    const periodoFimBR = formatDateBR(periodoFim)

    return (
        <Link to={`/colecoes/livro/${id}/${nomeUrl}`} className='flex flex-col drop-shadow-xl sombra cursor-pointer'>
            <div className='flex py-14 items-center justify-center bg-[#b3b7c2]'>
                Imagem do livro
            </div>
            <div className='flex flex-col text-sm px-2 py-3'>
                <h1 className='-mb-1'>Nome do livro: {nome}</h1>
                <h1 className='-mb-1'>Local: {local}</h1>
                <h1 className='-mb-1'>Período: {periodoInicioBR} - {periodoFimBR}</h1>

                <div className='flex items-center justify-between'>
                    <h1 className='truncate'>Escrivão: {escrivao}</h1>
                    <PiDotsThreeVerticalBold size={25} className='cursor-pointer -mt-2 -mr-1' />
                </div>
            </div>
        </Link>
    )
}

export default Livro